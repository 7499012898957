:root{
  --primary-blue: #3699ff;
  --secondary-blue: #6eb9f7;
  --primary-grey: #6c757d;
  --secondary-grey: #c0c6cc;
  --body-bg-color: #f5f5f9;
  --search-bcolor: transparent;
  --anchor-link:#a1acb8; 
  --label-color: #566a7f;
  --para-color: #697a8d;
  --field-border-color: #d9dee3;
  --primary-color: #f4a325;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Sen';
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--para-color);
  font-weight: 500;
  line-height: normal;
  font-family: 'Sen';
}

p{
  color: var(--para-color);
  font-weight: 400;
  line-height: normal;
  font-family: 'Sen';
}

button{
    color: #ffffff !important;
    background-color: #EE6100 !important;
    border: none !important;
    border-radius: 48px !important;
}

body{
    background-color: --body-bg-color !important;
}
.link{ 
  cursor: pointer; 
}
.document-dropzone {
    background: #fdfdfd !important;
    border-radius: 10px !important;
    font-size: .875rem;
    text-align: center;
    padding: 60px 20px;
    overflow: hidden;
    border: 1px solid #000 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: border .24s ease-in-out;
    color: var(--form-input-textcolor) !important;

    p{
        color: var(--form-input-textcolor);
    }
}

.btn-primary {
    color: var(--sidebar-color);
    background-color: --primary-blue;
    box-shadow: 0 1px 10px 1px --primary-blue !important;
    &:hover {
      color: var(--sidebar-color);
      background: --primary-blue
        radial-gradient(circle, transparent 1%, --primary-blue 1%) center/15000%;
      box-shadow: 0 1px 15px 1px --primary-blue !important;
      transition: all 0.5s ease;
    }
    &:active {
      color: var(--sidebar-color);
      background-color: --secondary-blue;
      background-size: 100%;
      transition: background 0s;
    }
  }

  .btn-secondary{
    color: white;
    background-color: --primary-grey;
    box-shadow: 0 1px 10px 1px --primary-grey !important;
    &:hover { 
        color: white;             
        background: --primary-grey radial-gradient(circle, transparent 1%, --primary-grey 1%) center/15000%;
        box-shadow: 0 1px 15px 1px --primary-grey !important;
        transition: all 0.5s ease; 
    }
    &:active {   
        color: white;           
        background-color: --secondary-grey;
        background-size: 100%;
        transition: background 0s;
    }  
  }

.layout-page{
  padding-left: 300px;
}
.sidebar-close .layout-page{
  padding-left: 110px;
}

label{
  color: #000;
  font-size: 14px;
  line-height: 23.04px;
  text-transform: capitalize;
}

//breadcrumb

.breadcrumb-title{
  font-size: 22px;

  .breadcrumb{
    margin: 0 !important;
  }
  .hide_breadcrumb{
    display: none;
  }
  .breadcrumb-item{
    &.active{
      color: var(--text-color);
    }
  } a{
    color: var(--text-color);

    &:hover{
        color: var(--link-dark-color);
    }
  }
}

// category-page-scss

.two-column-wrapper{
    form{
      flex: auto;
    }
}

.inner-block{
    background-color: #ffffff !important;
    border: none !important;
    border-radius: 20px !important;

    h5{
      color: #000;
      font-size: 22px;
      font-weight: 600;
      line-height: 14.4px;
    }
}

.form-control{
    color: var(--form-input-textcolor) !important;
    border-radius: 10px !important;
    border: 1px solid #000 !important;
    background: #fdfdfd !important;

    &:focus{
        border-color: var(--active-color) !important;
        box-shadow: 0 0 0 0.25rem rgba(244, 163, 37, 0.25) !important;

    }
}

.css-1s2u09g-control{
  color: var(--form-input-textcolor) !important;
  border-radius: 10px !important;
  border: 1px solid #000 !important;
  background: #fdfdfd !important;
}

#user_type{
  display: flex;
  gap: 50px;
}

#active{
  display: flex;
  gap: 75px;
}

.form-in{
  background: var(--form-in-bg) !important;
}

// ag grid dark mode colors

.ag-header{
    background-color: #f2f2f2;
    border-bottom: none;

    span.ag-header-cell-text{
        color: #000;
        font-size: 16px;
        font-family: 'Sen';
        font-weight: 600;
        line-height: 14.4px;
        height: 18px;
    }
}

.ag-theme-content-text-center{
  height: 100%;
  display: flex;
  align-items: center;
}

.ag-row {
    background-color: #ffffff;
    color: var(--text-color);
    border-top: 2px solid #f2f2f2;
    height: 50px;
    cursor: pointer;
}
.ag-row-odd {
    background-color: #f9f9f9 !important;
}
.ag-paging-panel {
    background: #f2f2f2;
    border: none !important;
    color: var(--text-color);
}
.ag-root-wrapper {
    border: none !important;
}
.ag-body-viewport{
    padding-right: 7px;
    background-color: #f2f2f2;
    scrollbar-width: thin;
}
.ag-icon-grip::before {
    color: var(--ag-icon-grip);
}
.fa-pen-to-square path{
  color: #4be094;
}
.fa-trash-can{
  color: #ff4747;
}
.ag-row-hover .ag-cell{
    color: #ffffff !important;

    .link{
      filter: brightness(0) invert(1);
    }
}
.ag-row-selected .ag-cell{
  color: #ffffff !important;

  .link{
    filter: brightness(0) invert(1);
  }
}
.ag-row-hover, .ag-row-selected{
    background-color: #EE6100 !important;

    .ag-icon-grip::before {
        color: var(--ag-icon-hover) !important;
    }

    .fa-pen-to-square path{
        color: var(--fa-pen-hover);
    }
    .fa-trash-can{
        color: var(--fa-trash-hover);
    }
}

.ag-ltr {
  // .ag-header-cell-resize{
  //   display: none;
  // }
  .ag-cell{
    color: #000 !important;
    font-size: 16px;
    font-family: 'Sen';
    font-weight: 500;
  }
}

.modal_button{
    width: auto !important;
    height: auto !important;
    padding: 15px !important;
}

span.input-group-text{
  margin: 0 5px !important;
}

.card-footer{
  padding-top: 20px !important;
}

//scrollbar

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(#f2f2f2); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--scrollbar-thumb); 
    -webkit-box-shadow: inset 0 0 6px var(--scrollbar-thumb); 
}
::-webkit-scrollbar-thumb:window-inactive {
    background: var(--scrollbar-thumb);
}

.list_button{
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none!important;
  color: #ffffff !important;
  margin-bottom: 40px;
  width: 191px;
  height: 48px;
  padding: 10px;
  border-radius: 48px !important;
  background: #EE6100 !important;
  text-transform: uppercase;
  text-align: center !important;
  font-size: 13.44px !important;
  font-family: 'Sen' !important;
  font-weight: 600 !important;
  line-height: 14.4px !important;

  &:hover{
    color: #ffffff;
  }
}

.flexible_button{
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none!important;
  color: #ffffff !important;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  padding: 15px 20px !important;
  border-radius: 48px !important;
  background: #EE6100 !important;
  text-transform: uppercase;
  text-align: center !important;
  font-size: 13.44px !important;
  font-family: 'Sen' !important;
  font-weight: 600 !important;
  line-height: 14.4px !important;

  &:hover{
    color: #ffffff;
  }

}

.combined_breadcrumb {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
    padding: 88px 41px 40px 54px;
}

.document-name {
  color: #000;
  font-size: 46px;
  font-family: 'Sen';
  font-weight: 500;
  line-height: 14.4px;
}

.breadcrumb-item{
  display: flex;
  font-size: 20px;
}

.error404{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: auto;

  img{
    width: 60%;
  }
}

.user_text{
  color: var(--text-color);
}

.scroll-width-thin {
  scrollbar-width: thin;
}


.ag-horizontal-left-spacer {

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 12px !important;
  }
}
.ag-body-horizontal-scroll-viewport{

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 12px !important;
  }
}

.breadcrumb_message{
  color: rgba(0, 0, 0, 0.50);
  font-size: 22px;
  font-family: 'Sen';
}

.dropdown{
  width: 100%;

  button{
    height: 48px;
    width: 100%;
    background: #333333 !important;
    border: 3px solid #5c5c5c !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #FFF;
    font-size: 13.44px;
    font-family: 'Sen';
    line-height: 14.4px;
    text-transform: uppercase;
  }

  .dropdown-menu.show{
    width: 100%;
  }

  .dropdown-toggle::after{
    content: url('./assets/images/down_icon.svg');
    border: none;
  }
  
}

.translation-aggrid .ag-cell {
  white-space: break-spaces !important;
}

.translation-aggrid .ag-center-cols-viewport {
  overflow-x: hidden  !important;
}

.translation-aggrid .ag-ltr {
  .ag-header-cell-resize{
    display: unset !important;
  }
}

.translation-aggrid .ag-cell-inline-editing input{
  color: var(--text-color)!important;
}
.translation-aggrid .ag-input-field-input {
  font-size: 16px !important;
}
.translation-aggrid .ag-header-cell-resize {
  z-index: 1;
}



.text-column {
  display: flex;
  justify-content: flex-start;
}

.text-column .ellipsis {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-icon {
  margin-right: 10px;
}

.role_main{
  label{
    margin: 0;
  }
  .role_header{
    background: #f2f2f2;
    align-items: center;
    padding: 5px 0;
  }
  .role_col:nth-child(even){
    padding: 5px 0;
  }
  .role_col:nth-child(odd){
    background: #f9f9f9;
    padding: 5px 0;
  }
  .role_col:last-child{
    border-bottom: 1px solid #f2f2f2;
  }
}

.translation_block{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;

  label,input{
    cursor: pointer;
  }

  .content_block{
    display: none;

    &.active{
      display: block;
    }

    &.inactive{
      display: none;
    }
  }

  .translation_innerblock{
    border-bottom: 1px solid #cccccc;

    &:last-child{
      border: none;
    }
  }
}

.autologout{
  .btn-close, .modal-footer {
	display: none;
  }
}

.disable{
  color: #9E9E9E !important;
  pointer-events: none;
  background-color: #ebebeb !important;
}

.hideNode{
  display: none;
}

.ag-picker-field-wrapper {
  background: #fff !important;
}

.shapes img{
  filter: brightness(0) saturate(100%) invert(95%) sepia(10%) saturate(964%) hue-rotate(303deg) brightness(105%) contrast(98%);
}

.cancel_block{
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  .cancel_button{
    gap: 15px;
  }
}

.ava_image img{
  object-fit: cover;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
input {
    font-family: 'Sen';
}

.login_main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

    .bg_video, .login_form_block {
        width: 50%;
        height: 100vh;
    }

    .content {
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        background: rgba(0, 0, 0, 0.55);
        width: 100%;
        height: 100vh;
        justify-content: center;
        font-family: 'Sen';

        img{
            width: 20%;
        }

        h1{
            font-style: normal;
            font-weight: 800;
            font-size: 70px;
            line-height: 64px;
            color: #FFFFFF;
            font-family: 'Sen';
            text-align: left;

            span{
                display: block;
                font-family: 'Sen';
            }
        }
    }

    .login_form_block{
        padding: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background: var(--login-background);
        h3{
            font-family: 'Sen';
            font-style: normal;
            font-weight: 800;
            font-size: 38.4px;
            line-height: 14px;
            color: var(--login-h3);
            margin-bottom: 43px;
        }
        p{
            font-family: 'Sen';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: var(--login-p);
        }
        .intro_text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 450px;
            width: 100%;
        }

        form{
            label{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 23px;
                color: var(--login-p);
                font-family: 'Sen';
            }
            input{
                border: 1px solid #000000 !important;
            }
            .submit-btn{
                background: #BCBCBC !important;
                border-radius: 48px;
                font-style: normal;
                font-weight: 600;
                font-size: 13.44px;
                line-height: 14px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF !important;  
                font-family: 'Sen';

                &.active{
                    background: #EE6100 !important;
                }

                &.inactive{
                    background: #BCBCBC !important;
                }
            }

            .form-check-label{
                text-transform: capitalize;
            }

            .text-body{
                font-family: 'Sen'; 
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 23px;
                text-align: right;
                color: var(--login-p) !important;
            }
        }
    }
}

.video-container {
    height: auto;
    width: auto;
    position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
.video-container .caption {
  z-index: 1;
  position: relative;
}

.mobile-screen {
    display: none;
}

.login-dark-mode {
    position: absolute;
    z-index: 99999 !important;
    top: 25px;
    right: 50px;
    background-color:transparent;
    border-radius: 6px;
    transition: all .3s ease;
    transition: var(--tran-05);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 180px;
    gap: 15px;

    .text{
        color:var(--form-input-textcolor);
    }

    .icon{
        position: unset !important;

        svg{
            height: 1.5em;
        }
    }
    .toggle-switch{
        position: relative;
        border-radius: 100px;
        background-color: var(--primary-color-light);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: block;

        .switch {
            display: block;
        }
        
    }
}


  
  .login-dark-mode .sun-moon .icon{
  
    .light-sun {
      display: block;
    }

    .dark-moon {
        display: none;
    }

    path{
        fill: var(--login-p);
    }
  }
  
  body.dark .login-dark-mode .sun-moon .icon{
    .light-sun {
      display: none;
    }
  
    .dark-moon {
      display: block;
      path{
        fill: #ffffff;
      }
      
    }

    path{
        fill: var(--login-p);
    }
  }

@media (max-width: 1200px) {
    .main{
        .content {
            h1 {
                font-style: normal;
                font-weight: 800;
                font-size: 55px;
                line-height: 54px;
                color: #FFFFFF;
                font-family: "Sen";
            }
            img {
                width: 35%;
            }
        }

        .login_form_block h3 {
            font-family: "Sen";
            font-style: normal;
            font-weight: 800;
            font-size: 38.4px;
            line-height: 44px;
            color: var(--login-h3);
            margin-bottom: 43px;
        }
    }
}

// @media (max-width: 840px) {
//     .main{
//         .bg_video{
//             display: none;
//         }
//         .login_form_block {
//             width: 100%;
//             align-items: center;

//             .intro_text {
//                 align-items: center;
//                 text-align: center;
//             }
//         }

//         .button_block{
//             text-align: center;
//         }
//     }
// }

@media (max-width: 1024px) {
    .mobile-screen {
        display: block;
        background: #242526;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        z-index: 99999 !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

.submit-btn {
    border: none;
    outline: none;
    border-radius: 8px;
    min-width: 200px;
    min-height: 36px;
    cursor: pointer;
    outline: none;
  }
  
  .submit-btn:disabled {
    background: #e7e8e9 !important;
    color: #9fa3a9;
    cursor: not-allowed;
  }
  
  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
$main: #000000;
$sec:var(--sidebar-li-bg);
$little-cursor: white;

.loaderwrapper {
    position: fixed;
    z-index: 2 !important;
    width: 100%;
    min-width: calc(100vw - 300px);
    height: 100%;
    min-height: calc(100vh - 111px);
    background: #000000;
    opacity: 0.8;
    overflow: hidden !important;
    top: 0;
    /* left: 0; */
    right: 0;
} 
#outer{
  margin: auto;
  margin-top: 22%;
  background: $main;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  position: relative;
  left: 8%;
}
#middle{
  height: 60px;
  width: 60px;
  margin:auto;
  position: relative;
  top:20px;
  border-radius: 50%;
  background-image: linear-gradient(150deg, transparent 50%, $sec 50%),linear-gradient(90deg, $sec 50%, $little-cursor 50%);
  animation: rotation 1200ms infinite linear;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}
#inner{
  background: $main;
  height: 48px;
  width: 48px;
  margin:auto;
  position: relative;
  top:6px;
  border-radius: 50%;
}
@keyframes rotation {
from {
  -webkit-transform: rotate(270deg);
}
to{
  -webkit-transform: rotate(630deg);
  }
}
.header {
    position: sticky;
    top: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
    -webkit-backdrop-filter: saturate(200%) blur(6px);
    backdrop-filter: saturate(200%) blur(6px);
    background-color: #ffffff !important;
    height: 111px;
    gap: 25px;
    padding-right: 35px;
    z-index: 1;

    h6{
        color: #000;
        text-align: right;
        font-size: 20px;
        font-family: 'Sen';
        line-height: 14.4px;
        text-transform: uppercase;
        cursor: default;
    }

    .avatar{
        border-radius: 50px;
        border: 5px solid transparent;

        &.popup{
            border-radius: 50px;
            border: 5px solid #EE6100;
        }
    }

    .settings_popup{
        margin-top: 15px;
        position: absolute;
        top: 90px;
        right: 15px;
        background: #292929;
        color: #ffffff;
        width: 186px;
        height: auto;
        border-radius: 15px;

        ul{
            padding: 30px;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
            gap: 15px;

            li{
                color: #fff;
                text-align: right;
                font-size: 12px;
                font-family: 'Sen';
                font-weight: 600;
                line-height: 14.4px;
                text-transform: uppercase;
                cursor: pointer;
                list-style-type: none;

                &.seperator{
                    border: 1px solid #FFFFFF33;
                    width: 100%;
                    margin: 0 auto;
                }

                &.logout{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                a{
                    color: #ffffff;
            
                    :hover{
                        color: #ffffff;
                    }
                }
            }
        }

        .arrow_pointer{
            position: absolute;
            background: #292929;
            width: 42.128px;
            height: 42.128px;
            border-radius: 15px;
            top: -13px;
            right: 25px;
            transform: rotate(45deg);
            z-index: -10;
        }
    }
}
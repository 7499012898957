:root {
  /* ===== Colors ===== */
  --body-color: #eef2ff;
  --sidebar-color: rgba(0, 0, 0, 0.8);
  --sidebar-header-color: rgba(0, 0, 0, 0.2);
  --sidebar-li-bg: #EE6100;
  --sidebar-li-color: #ffffff;
  --primary-color: #f4a325;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;
  --form-input-bg: #ffffff;
  --form-input-textcolor: #212529;
  --link-dark-color: #f4a325;
  --form-border: #ced4da;
  --dark-grid-border: #ced4da;
  --dark-grid-odd-bg: #fcfcfc;
  --scrollbar-track: #cccccc;
  --scrollbar-thumb: #707070;
  --ag-icon-grip: #707070;
  --ag-icon-hover: #ffffff;
  --fa-pen-hover: #ffffff;
  --fa-trash-hover: #ffffff;
  --login-h3: #000000;
  --login-p: #000000;
  --login-background: #ffffff;
  --form-in-bg: #D9D9D90D;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

body.dark {
  min-height: 100vh;
  background-color: var(--body-color);
  transition: var(--tran-05);
}

::selection {
  background-color: #ee6100;
  color: #fff;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
  --active-color: #f4a325;
  --dark-grid-bg: #595a5b;
  --form-input-bg: #3a3b3c;
  --form-input-textcolor: #ffffff;
  --form-border: #595a5b;
  --dark-grid-odd-bg: #303030;
  --dark-grid-border: #303030;
  --scrollbar-track: #242526;
  --scrollbar-thumb: #707070;
  --ag-icon-grip: #cccccc;
  --ag-icon-hover: #242526;
  --fa-pen-hover: #242526;
  --fa-trash-hover: #242526;
  --login-h3: #ffffff;
  --login-p: #cccccc;
  --login-background: #242526;
  --form-in-bg: #3A3B3C;
}

#menu__toggle {
  opacity: 0;
}

/* ===== Sidebar ===== */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 100;
  box-shadow: 0px 0px 40px 2px rgb(0 0 0 / 10%);

  .menu-links{
    .nav-link{
      margin-bottom: 12px;
      span{
        font-family: 'Sen';
        font-style: normal;
        font-weight: 600;
        font-size: 13.44px;
        line-height: 18px;
        white-space: break-spaces;
        text-transform: uppercase;
        color: var(--sidebar-li-color);
      }
    }
  }

  .separator-pages{
        position: relative;
        height: 100%;
        margin: 20px 0;
        display: flex;
        padding: 0;
        .pages-separator{
            opacity: 1;
            transition: all 0.3s ease-in-out;
            letter-spacing: 0.2px;
            color: #a1acb8;
            font-size: 15px;
            width: 100%;
            text-align: center;
            height: 22px;
            font-weight: 600;
        }
        .line-separator-before{
            margin: 0 auto;
            height: 5px;
            position: absolute;
            left: 0;
            transition: all 0.3s ease-in-out;
            width: 18%;
            border-top: 1px solid #f5f5f9;
            border-bottom: 1px solid #f5f5f9;
        }
        .line-separator-after{
          margin: 0 auto;
          height: 5px;
          position: absolute;
          right: 0;
          transition: all 0.3s ease-in-out;
          width: 18%;
          border-top: 1px solid #f5f5f9;
          border-bottom: 1px solid #f5f5f9;
      }
        &:hover{
            background-color: transparent;           
        }
  }

  .bottom-separator-pages {
    position: relative;
    height: 2vh;
    margin: 20px 0;
    display: flex;
    padding: 0;

    .bottom-pages-separator {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      letter-spacing: 0.2px;
      color: #a1acb8;
      font-size: 13px;
      width: 100%;
      text-align: center;
      height: 22px;
    }

    .bottom-line-separator-before {
      background: #f5f5f9;
      margin: 0 auto;
      height: 1px;
      position: absolute;
      left: 0;
      transition: all 0.3s ease-in-out;
      width: 18%;
    }
    .bottom-line-separator-after {
      background: #f5f5f9;
      margin: 0 auto;
      height: 1px;
      position: absolute;
      right: 0;
      transition: all 0.3s ease-in-out;
      width: 18%;
    }
    &:hover{
      background-color: transparent;           
    }
  }

  &.close {
    width: 124px;

    .menu-links .separator-pages{
        .line-separator{
            left: 15px;
            width: 50%;
        }
        // &:hover{
        //     background-color: transparent;
        // }
        .pages-separator{
            overflow: hidden;
            opacity: 0;
        }
    }

    .menu-bar {
      padding: 25px 25px 0px 31px;
    }

    .bottom_ul_links {
      width: 110%;
    }

    .dropdown button {
      padding-left: 9px;

      .name{
        opacity: 0;
      }
    }

    .dropdown .dropdown-toggle::after {
      opacity: 0;
    }

    .bottom-pages-separator {
      opacity: 0;
    }

    .bottom-line-separator-before {
      width: 50%;
    }
    .bottom-line-separator-after {
      width: 50%;
    }

    // &:hover{
    //     width: 300px;

    //     .separator-pages{
    //         .line-separator{
    //             left: 0;
    //             width: 15%;
    //             transition: all .3s ease-in-out;
    //         }
    //         .pages-separator{
    //             opacity: 1;
    //             transition: all .3s ease-in-out;
    //         }
    //     }
    // }

    .line-separator-before, .line-separator-after{
        width: 50%;
    }

    .menu-bar {
      align-items: center;
    }
    li {
      width: 51px;

      .active {
        width: 51px;
      }
    }
    .sidebar .menu-bar .mode {
      width: 51px;
    }
    .icon {
      min-width: 50px;

      path{
        fill: #ffffff;
      }
    }
    .menu-bar .bottom-content .toggle-switch {
      min-width: 50px;
    }
    .copyright{
      opacity: 0;
      pointer-events: none;
    }

    .image-text {
      .image img{
        content: url('../../assets/images/closed-logo.svg');
      }
    }
  }

  &.open .sidebar-collapsed-logo{
    min-width: 200px !important;
    border-radius: 6px !important;
    justify-content: center !important;

    img{
        content : url('../../assets/images/sunrise-medical-logo-color.svg') !important;
        width: 190px !important;
        border-radius: 6px !important;
      }
  }

  li {
    height: 48px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 48px;
    width: 97%;
  }

  header .image {
    min-width: 200px;
    border-radius: 6px;
  }

  .icon {
    min-width: 60px;
    border-radius: 6px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    path{
      fill: #ffffff;
    }
    img{
      width: 24px;
    }
  }

  .text,
  .icon {
    color: var(--text-color);
    transition: var(--tran-03);
  }

  .text {
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    opacity: 1;
    background: #181818;
    border-radius: 48px;
    height: 48px;

    &:hover{
      background: var(--sidebar-li-bg);
    }
  }

  .icon svg{
    font-size: 15px;
  }

  &.close {
    .text {
        .icon{
            opacity: 1;
        }
        span{
            opacity: 0;
            pointer-events: none;
        }
    }
    // &:hover{
    //     .text {
    //         span{
    //             opacity: 1;
    //         }
    //     }

    // }
  }

  header {
    position: relative;
    background: var(--sidebar-header-color);
    height: 111px;
    display: flex;
    align-items: center;
    justify-content: center;

    .image-text {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo-text {
      display: flex;
      flex-direction: column;
    }
  }

  .css-1s2u09g-control{
    background: #181818 !important;
    border: none !important;
    border-radius: 48px !important;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    height: 48px;

    // :hover{
    //   background-color: transparent !important;
    //   border: 3px solid rgba(255, 255, 255, 0.2) !important;
    //   border-radius: 48px !important;
    // }
  }
  .css-qc6sy-singleValue{
    color: #ffffff !important;
    text-transform: uppercase;
  }

  .css-1pahdxg-control{
      background-color: transparent !important;
      border: none !important;
      border-radius: 48px !important;
      box-shadow: none;
    // :hover{
    //   background-color: transparent !important;
    //   border: 3px solid rgba(255, 255, 255, 0.2) !important;
    //   border-radius: 48px !important;
    // }
  }

  .css-1okebmr-indicatorSeparator{
    display: none;
  }
}

.copyright{
    text-align: center;
}

/* ===== Reusable code - Here ===== */

/* =========================== */

header .image-text {
  .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
  }

  .profession {
    font-size: 16px;
    margin-top: -2px;
    display: block;
  }
}

.sidebar header {
  .image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 146.4px;
      height: 37.6px;
    }
  }

  .toggle {
    position: absolute;
    right: -21px;
    transform: translateY(-50%) rotate(0deg);
    height: 30px;
    width: 30px;
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
    top: 272px;
    img{
      border: 5px solid var(--dark-grid-bg);
      border-radius: 50%;
    }
  }
}

body.dark .sidebar header .toggle {
  color: var(--dark-grid-bg);
  background-color: var(--active-color);
}

.sidebar {
  &.close .toggle {
    transform: translateY(-50%) rotate(180deg);
  }

  li {
    &.search-box {
      border-radius: 6px;
      background-color: var(--primary-color-light);
      cursor: pointer;
      transition: var(--tran-05);

      &:hover{
        background-color: var(--primary-color-light);

        .icon{
            color: var(--text-color);
        }
      }

      input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        background-color: var(--primary-color-light);
        color: var(--text-color);
        border-radius: 6px;
        font-size: 17px;
        font-weight: 500;
        transition: var(--tran-03);
      }
    }
    &:hover {
        background-color: var(--sidebar-li-bg);
        transition: var(--tran-03);

        .icon,
        .text {
          color: var(--text-color);
        }
    }

    .active{
        background-color: var(--sidebar-li-bg);
        color: var(--sidebar-color);
        transition: var(--tran-03);
        height: 48px;
        border-radius: 48px;

        .icon{
          color: var(--sidebar-color);
        }

        &:hover{
            background-color: var(--sidebar-li-bg);
            color: var(--sidebar-color);
            transition: var(--tran-03);

            .icon{
                color: var(--sidebar-color);
              }
        }
    }

    a {
      list-style: none;
      height: 100%;
      background-color: transparent;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 6px;
      text-decoration: none;
      transition: var(--tran-03);

      &:hover {

        .icon,
        .text {
          color: var(--text-color);
        }
      }
    }
  }
}

body.dark .sidebar li {
    &:hover {
        .icon,
        .text {
          color: var(--text-color);
        }
    }
    .active{
        background-color: var(--active-color);
        color: var(--sidebar-color);
        transition: var(--tran-03);

        .icon{
          color: var(--sidebar-color);
        }

        &:hover{
            background-color: var(--active-color);
            color: var(--sidebar-color);
            transition: var(--tran-03);

            .icon{
                color: var(--sidebar-color);
              }
        }
        &::before{
            background-color: var(--active-color);
        }
    }
}

.sidebar .menu-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 25px 0px;
}

@-moz-document url-prefix() {
  .sidebar .menu-bar {
    width: 100%;
    padding-right: 25px;
  }

  .moz_scroll {
    // overflow: hidden auto;
    scrollbar-color: #6f6f6f  #313131;
    scrollbar-width: thin;
  }
  .scroll_width_none {
      scrollbar-width: none !important;
  }
  .scroll::-webkit-scrollbar {
      width: 0.5em;
      background-color: #313131;
  }
  .scroll::-webkit-scrollbar-thumb {
      background-color: #6f6f6f;
  }

}

.bottom_ul_links::-webkit-scrollbar {
  width: 8px;
}

.bottom_ul_links{
  height: calc(100vh - 390px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 105%;
}

.sidebar .menu-bar .mode {
  position: relative;
  transition: var(--tran-05);
  background: #181818;
  border-radius: 48px;
  height: 48px;
  color: #fff;

  &:hover{
    background: var(--sidebar-li-bg);
    color: #fff;
  }
}

.sidebar.close {
    .mode-text{
        opacity: 0;
    }
    // &:hover{
    //     .mode-text{
    //         opacity: 1;
    //     }
    // }
}

.menu-bar .mode .sun-moon {
  height: 40px;
  width: 60px;
}

.mode .sun-moon .icon{
  position: absolute;

  .light-sun {
    display: block;
  }
  .dark-moon {
    display: none;
  }
}

body.dark .mode .sun-moon .icon{
  .light-sun {
    display: none;
  }

  .dark-moon {
    display: block;
  }
}

.menu-bar .bottom-content {
    position: relative;
    .separator{
        position: absolute;
        background: #f5f5f9;
        width: 100%;
        height: 1px;
    }

    .toggle-switch {
        position: absolute;
        right: 0;
        height: 100%;
        min-width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
      }
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--text-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

body.dark .switch::before {
  left: 20px;
}

.home {
  @media (min-width: 501px) {
    position: absolute;
    top: 0;
    top: 0;
    left: 250px;
    height: 100vh;
    width: calc(100% - 250px);
    background-color: var(--body-color);
    transition: var(--tran-05);
  }

  .text {
    font-size: 30px;
    font-weight: 400;
    color: var(--text-color);
    padding: 12px 60px;
  }
}

@media (min-width: 501px) {
  .sidebar.close ~ .home {
    left: 78px;
    height: 100vh;
    width: calc(100% - 78px);
  }
}

body.dark .home .text {
  color: var(--text-color);
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
    .home {
      position: relative;
    }
    .sidebar {
      padding: 0;
      height: 0;
      width: 100%;
      overflow: hidden;
      position: fixed;
      i.toggle {
        display: none;
      }
    }
  }
}

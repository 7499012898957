.profile_title{
    font-weight: 800;
    font-size: 16px;
    color: #000000;
    line-height: 14.4px;
}

.profilecard{
    form{
        label{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: var(--login-p);
            font-family: 'Sen';
        }
        input{
            border: 1px solid #000000 !important;
        }
    }

    .remove_button{
        background-color: #181818 !important;
    }

    input[type='radio'] {
        box-sizing: border-box;
        appearance: none;
        background: #ffffff;
        outline: 1px solid #000000;
        border: 3px solid #ffffff !important;
        border-radius: 4px;
        width: 18px;
        height: 18px;
      }
      
      input[type='radio']:checked {
        background: #EE6100;
      }

      .document-dropzone {
        position: absolute;
        right: 450px;
        top: 106px;
        width: 20%;
        padding: 35px 25px !important;
    }

    aside {
        position: absolute;
        right: 500px;
        width: 100px;
        top: 272px;
    }

    .profileimageuploader{
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        position: absolute;
        top: 118px;
        right: 40px;

        button{
            margin:0 !important;
        }
    }
}
.dashboard-card{
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
    background-color: #fff;
    min-height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5{
        color: #000;
        font-size: 22px;
        font-weight: 600;
        line-height: 14.4px;
    }

    label{
        color: #000;
        font-size: 14px;
        line-height: 23.04px;
        text-transform: capitalize;
    }
}
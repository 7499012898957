:root{
    --dark-grid-bg: #f5f5f9;
}
.grid-container {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;

    .d-none{
        display: none;
    }
}
.main {
    background-color: #f2f2f2;
    padding: 0px 41px 61px 54px;
    height: 100%;
    min-height: calc(100vh - 111px);
}
:root {
  --card-bg-color: #fff;
  --main-bg-color: #f5f5f9;
}
a{
  text-decoration: none !important;
}
p{
  color: #697a8d;
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
}
h6{
  color: #697a8d;
  font-weight: 600 !important;
}
.dashboard_block1{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 37px;
    margin-bottom: 41px;
    width: 100%;

    a{
      width: 50%;
    }
}
.dashboard_block2{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 37px;
  width: 100%;

  a{
    width: 50%;
  }
}
.inner_block{
    border-radius: 20px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    background-color: #fff;
    min-height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 230px;
    position: relative;
    overflow: hidden;
    padding: 27px;

  .shapes{

    img{
      position: absolute;
    }

    .img1{    
      top: -15px;
      right: -15px;
      width: 166px;
      height: 166px;
    }
    .img2{
      top: -20px;
      right: -20px;
      width: 154px;
      height: 154px;
    }
    .img3{
      top: -40px;
      right: -30px;
      width: 205px;
      height: 205px;
    }
    .img4{
      top: -10px;
      right: -10px;
      width: 166px;
      height: 166px;
    }
  }
}
.padding_css{
  padding-top: 88px;
}
.dashboard_title{
    color: #000;
    font-size: 46px;
    font-family: "Sen";
    font-weight: 500;
    line-height: 14.4px;
}
.dashboard_subtitle{
    color: rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-family: "Sen";
    line-height: 14.4px;
}
.dashboard_main{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 47px;
}
.block_title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

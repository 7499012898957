.red-color{
    color: #FF0000;
    list-style-type: none;
  }
  .green-color{
    color:#0d5c0d;
    list-style-type: none;
  }
  #passwordRequirements ul {
    padding: 0;
    margin-top: 5px;
  }
  #passwordRequirements {
    margin-top: 15px;
  }
  #passwordRequirements li span {
    margin-right: 5px;
    width: 16px;
  }

  .changepassword_popup{

    .modal-dialog {
      max-width: 520px;
      }
      
    .btn-close {
      background:  url('../../assets/images/icon-close-orange.svg') no-repeat !important;
      opacity: 1 !important;
      width: 27px !important;
      height: 24px!important;
    }
}